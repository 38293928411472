.sub-header-root {
    min-height: 35vh;
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 10%;
    gap: 10%;
    overflow: hidden;
}

.sub-header-text-content-container {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.sub-header-title {
    color: orange;
    font-size: 1.25rem;
    margin: unset;
    padding: unset;
}

.sub-header-subtitle {
    color: #000;
    font-size: 4rem;
    margin: unset;
    padding: unset;
    text-align: left;
}

.sub-header-desc {
    color: #555;
    text-align: left;
    font-weight: bold;
    line-height: 1.5rem;
}

.sub-header-desc .underline-span {
    border-radius: .25rem;
    text-decoration: underline wavy #ffa500;
}

.growth-svg {
    height: auto;
    width: auto;
    margin: 10% auto;
    transition: 300ms;
    align-self: center;
}


@media screen and (max-width : 1400px){
    .sub-header-text-content-container {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}
@media screen and (max-width: 1024px) {
    
    .growth-svg {
        height: 60%;
        width: auto;
        align-self: center;
    }

}

@media screen and (max-width: 768px){
    .sub-header-root {
        grid-template-columns: 1fr;
        gap: unset;
    }
    .sub-header-subtitle {
        font-size: 3rem;
    }

    .sub-header-desc {
        line-height: 1.5rem;
    }

    .growth-svg {
        display: none;
        /* padding-right: 1rem;
        height: 25%;
        right: 5%;
        align-self: unset;
        position: absolute;
        opacity: 0.25; */
    }
}