.intro-root{
    min-height: 100vh;
    max-height: 100vh;
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    overflow: hidden;
}

.contact-us-button {
    cursor: pointer;
    padding: 1rem 2rem;
    width: fit-content;
    align-self: center;
    justify-self: center;
    border-radius: .5rem;
    border: 1px solid orange;
    color: orange;
    transition: 500ms;
    animation-name: slide-in-from-right;
    animation-duration: 1.5s;
    animation-fill-mode: backwards;
    animation-delay: 1.5s;
    font-weight: bold;
    text-decoration: none;
    z-index: 1;
}

.contact-us-button:hover {
    background-color: orange;
    color: white;
}

.intro-text {
    flex: 1;
    align-self: center;
    opacity: 0;
    animation-name: slide-in-from-left;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
}

.intro-text h1 {
    font-size: 5rem;
    text-transform: capitalize;
    font-weight: 800;
    padding-bottom: 1rem;
    margin-bottom: 0;
    border-bottom: 1px dotted orange;
}


/* .intro-text .tech-span {
    font-style: oblique;
} */

.intro-root h2 {
    max-width: 100vw;
    max-height: 100vh;
    position: absolute;
    margin: 0;
    padding: 0;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    overflow: hidden;
    cursor: default;
}

.intro-root .intro-span:nth-child(4)::before{
    position: absolute;
    content: "Great";
    background-color: #000;
    animation-name: great;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}


.intro-root .intro-span {
    display: block;
    text-align: left;
    font-size: 8rem;
    opacity: .1;
    animation-name: fade-in-fade-out;
    animation-duration: 3s;
    animation-fill-mode: forwards;
}

.tech-span {
    color: orange;
}

@keyframes slide-in-from-left {
    from {
        transform: translateX(-50%);
    }

    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slide-in-from-right {
    from {
        transform: translateX(50vh);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes great {
    from {
        background-color: black;
        color: white;
        border-radius: 0;
    }

    to {
        padding-left: 1rem;
        padding-right: 1rem;
        background-color: white;
        color: black;
        border-radius: 0 2rem 0 2rem;
    }
}

@keyframes fade-in-fade-out {
    0% {
        opacity: 0;
        font-size: 15rem;
    }

    50% {
        opacity: 1;

    }

    100% {
        opacity: .07;
        font-size: 7rem;
    }
}

@media screen and (max-width: 1024px) {
    .intro-text {
        max-width: 100vw;
    }

    .intro-text h1 {
        font-size: 4rem;
        padding-bottom: 1rem;
        margin-bottom: 0;
        border-bottom: 1px dotted orange;
    }
    
    .intro-root .intro-span {
        display: block;
        text-align: left;
        font-size: 8rem;
        opacity: .1;
        animation-name: fade-in-fade-out;
        animation-duration: 3s;
        animation-fill-mode: forwards;
    }

    @keyframes fade-in-fade-out {
        0% {
            opacity: 0;
            font-size: 12rem;
        }

        50% {
            opacity: 1;

        }

        100% {
            opacity: .07;
            font-size: 6rem;
        }
    }
}

@media screen and (max-width: 768px){
    .intro-root {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        padding: 0 .5rem;
    }
    .intro-text {
        animation-name: slide-in-from-top;
    }

    .contact-us-button {
        animation-name: slide-in-from-bottom;
    }

    .intro-text h1 {
        align-items: center;
        font-size: 3rem;
    }

    @keyframes fade-in-fade-out {
        0% {
            opacity: 0;
            font-size: 10rem;
            line-height: 13rem;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: .07;
            font-size: 4rem;
            line-height: 7rem;
        }
    }

    @keyframes slide-in-from-top {
        from {
            transform: translateY(-50%);
        }

        to {
            transform: translateY(0%);
            opacity: 1;
        }
    }

    @keyframes slide-in-from-bottom {
        from {
            transform: translateY(50vh);
            opacity: 0;
        }

        to {
            transform: translateY(0);
            opacity: 1;
        }
    }
}