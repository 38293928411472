.contact-root {
    background-color: #fff;
    min-height: 50vh;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 5% 10%;
    gap: 5%;
}

.contact-desc {
    flex: 1;
}

.contact-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.contact-form label span {
    display: block;
    margin-bottom: .5rem;
    font-weight: bold;
    opacity: .75;
}

.contact-form label {
    margin-top: 1rem;
    margin-bottom: .5rem;
    width: 100%;
}

.contact-form label input, .contact-form label textarea {
    min-width: 45ch;
    width: 70%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .25rem;
    padding-bottom: .25rem;
    border-radius: .5rem;
    outline: 1px solid #555;
    border: none;
    background-color: #eee;
    transition: 300ms;
    font-size: 1.05rem;
}

.contact-form label input {
    line-height: 2rem;
    transition: 300ms;
}

.contact-form label textarea {
    min-height: 10rem;
    padding: 1rem;
    transition: 300ms;
}

.contact-form label input:focus,
.contact-form label textarea:focus {
    /* border: 1px solid orange; */
    outline: 1px solid orange;
    background-color: #fff;
}

.btn {
    cursor: pointer;
    min-width: 45ch;
    width: 70%;
    font-size: 1.20rem;
    background-color: orange;
    border: none;
    padding: .5rem;
    border-radius: .5rem;
    color: #fff;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: .5rem;
    transition: 300ms;
}
.btn:hover {
    background-color: darkorange;
}

.contact-form p {
    color: orange;
    background-color: #eee;
    width:  fit-content;
    font-weight: bold;
    padding: .5rem 1rem;
    border-radius: 1rem;
    transition: 300ms;
}

.invisible {
    display: none;
    transition: 300ms;
}

@media screen and (max-width: 1024px){
    .contact-root {
        max-width: 100vw;
        flex-wrap: wrap;
        overflow: hidden;
    }

    .contact-desc {
        width: 80vw;
    }

    .contact-form {
        max-width: 100%;
    }

    .contact-form label input,
    .contact-form label textarea {
        min-width: unset;
        width: 100%;
    }

    .btn {
        min-width: unset;
        width: 100%;
    }
}