.App {
  text-align: center;
}

.divider {
  width: 100%;
  height: 1px;
  opacity: .5;
  background: linear-gradient(to right, #000, #fff, #fff, #000);
}

