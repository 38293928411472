.philosophy-root {
    min-height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10%;
    gap: 10%;
}

.philosophy-desc-container {    
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.philosophy-header {
    font-size: 4rem;
    margin: unset;
}

.philosophy-desc {
    opacity: 0.9;
    font-size: 1.1rem;
    line-height: 1.75rem;
    letter-spacing: 1px;
}

.philosophy-boxes-container {
    width: 100%;
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1rem;
    
}

.philosophy-box {
    width: 100%;
    height: 100%;
    min-height: 8rem;
    justify-self: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    color: #000;
    background-color: #fff;
}

.philosophy-box:hover {
    box-shadow: inset 2px 2px 10px 2px rgba(0, 0, 0, 0.25);
}

.philosophy-box span {
    font-size: 2rem;
}

@media screen and (max-width: 1024px){
    .philosophy-root {
        min-height: 100vh;
        flex-wrap: wrap;
        padding-bottom: 5rem;
    }

    .philosophy-header {
        font-size: 3rem;
    }
}