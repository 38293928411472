.services-root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 10%;
}

.services-root li, .services-root ul {
    list-style-type: none;
    text-align: left;
    padding: 0;
}

.services-title {
    font-size: 3.5rem;
}

.services-title span {
    background-color: #fff;
    color: orange;
    padding: 0 1rem;
    border-radius: 1rem 0 1rem 0;
}

.services-header p {
    font-size: 1.2rem;
    font-weight: 600;
}

.dev-services-title {
    text-align: left;
    margin-top: 5%;
    font-size: 2.5rem;
}

.dev-services-title i {
    margin-right: 1rem;
}

.services {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-evenly;
}

.services > * {
    min-height: 100%;
    margin-top: 2%;
    background-color: white;
    color: black;
    transition: 300ms;
}

.services h3 {
    text-align: left;
}

.service-box {
    flex: 1;
    padding: 2rem;
    border-radius: .5rem;
    overflow-x: hidden;
}

.service-box> h3 {
    font-size:1.5rem;
    margin-top: 0.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 0;
    color: #333;
    border-bottom: 1px solid #ddd;
}

.service-box li {
    display: flex;
    align-items: baseline;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
    font-weight: 500;
    font-size: 1.15rem
}

.service-box li i {
    color: orange;
    margin-right: 1rem;
    font-size: 1.1rem
}

.service-box .web-svg {
    position: absolute;
    max-height: 10rem;
    margin: 0;
    padding: 0;
    left: -30%;
    opacity: .1;
}

.service-box sup {
    font-size: 1.5rem;
}

@media screen and (max-width: 1024px)  {
    .services-title {
        font-size: 3rem;
    }

    .dev-services-title {
        font-size: 1.75rem;
    }

    .services {
        flex-wrap: wrap;
        margin-bottom: 2rem;
    }

    .services > * {
        min-width: fit-content;
    }
}